<template>
  <a-row :gutter="10">
    <a-col :span="10">
      <inspect @check="inspectChange"/>
    </a-col>
    <a-col :span="14">
      <vehicle
          ref="inspectVehicle"
      />
    </a-col>
  </a-row>
</template>

<script>
import inspect from "@/views/secure/inspect/inspect.vue";
import vehicle from "@/views/secure/inspect/vehicle.vue";
export default {
  name: 'Index',

  components: {
    inspect,
    vehicle
  },
  data() {
    return {}
  },
  created() {

  },
  methods: {
    inspectChange(item) {
      this.$refs.inspectVehicle.inspect_number = item.inspect_number
      this.$refs.inspectVehicle.queryParam.status = -1
      this.$refs.inspectVehicle.queryParam.inspect_id = item.inspect_id
      this.$refs.inspectVehicle.handleRefresh(true)
    }
  }
}
</script>
